// Customizable Area Start
import React from "react";
import {
    Box, Typography,
    Button, Card,
    TextField, IconButton,
    TableContainer, Table,
    TableHead, TableRow,
    TableCell, TableBody,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
    createTheme,
    styled
} from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomFormControllerWeb, {
    Props,
} from "./CustomFormController.web";
import { capitalizeFirstLetters, handleDownload } from "../../../components/src/utils.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { searchIcon } from "../../multipageforms/src/assets";
import { deleteSmallIcon, downloadSmallIcon } from "./assets";
import AccountLetterForm from "../../../components/src/AccountLetterForm.web";
import AccountLetterViewModal from "../../../components/src/AccountLetterViewerModal.web";
import ConfirmSaveDraft from "../../../components/src/ConfirmSaveDraft.web";
import SuccessALModal from "../../../components/src/SuccessALModal.web";
import DeleteConfirmationPopup from "../../../components/src/DeleteConfirmationPopup.web";
import SortingAlDropdown from "../../../components/src/SoringALDropdown.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

export default class DepositRequest extends CustomFormControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        zIndex: -1,
        position: "absolute",
        [theme.breakpoints.up(900)]: {
            zIndex: 0,
            position: "inherit",
        },
        "& .container": {
            [theme.breakpoints.down(900)]: {
                width: "calc(100vw - 4rem)",
                padding: "5px 10px 10px 3rem",
                position: "absolute",
                top: "0",
                zIndex: 99,
            },
            [theme.breakpoints.down(750)]: {

            },
            width: "calc(100vw - 315px - 5rem)",
            padding: "4rem 10px 10px 2rem",
            height: "calc(100vh - 4rem - 10px)",
            overflowY: "scroll",
        },
        "& .statusContentFlexBox": {
            display: 'flex',
            gap: 10,
            flexDirection: 'column',
            [theme.breakpoints.down(750)]: {
                flexDirection: 'row'
            }
        },
        "& .iconLineFlexBox": {
            display: "flex",
            alignItems: "center",
            gap: '20px',
            marginBottom: '24px',
            [theme.breakpoints.down(750)]: {
                flexDirection: 'column'
            }
        },
        "& .horizontalDivider": {
            [theme.breakpoints.down(750)]: {
                height: '40px',
                width: '6px'
            },
            height: '6px',
            borderRadius: '5px',
            width: '100%',
            margin: '20px 0p',
        },
        "& .iconButton": {
            padding: theme.spacing(1),
        },
        "& .icon": {
            width: 24,
            height: 24,
        },
        "& .buttonStyle": {
            textTransform: 'none',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '18px'
        },
        "& .stepFont": {
            color: '#64748B',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px'
        },
        "& .titleFont": {
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            marginBottom: '16px'
        },
        "& .headingText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px',
                lineHeight: '30px',
            },
            overflow: 'hidden',
            color: '#1E293B',
            textOverflow: 'ellipsis',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '-0.15px',
        },
        "& .searchBarContainer": {

            [theme.breakpoints.down(750)]: {
                display: "none",
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            borderRadius: '8px',
            border: '1px solid #64748B',
        },
        "& .searchBarInput": {
            width: '100%',
        },
        "& .mobileSearch": {
            [theme.breakpoints.down(750)]: {
                width: "30px",
                height: "30px",
            },
            [theme.breakpoints.up(750)]: {
                display: "none",
            },
        },
        "& .customButton": {
            [theme.breakpoints.down(900)]: {
                padding: "14px 8px"
            },
            [theme.breakpoints.down(750)]: {
                padding: '7px 5px !important',
                "& .MuiButton-startIcon": {
                    marginRight: '0'
                }
            },
            display: 'flex',
            padding: '10px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            background: '#1B4FE4',
        },
        "& .buttonText": {
            [theme.breakpoints.down(1451)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(1370)]: {
                fontSize: "13px",
            },
            [theme.breakpoints.down(1262)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(900)]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down(750)]: {
                display: 'none',
            },
            color: '#FFF',
            textTransform: "none",
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            flexWrap: "nowrap",
        },
        "& .newRequestText": {
            color: '#334155',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
            marginBottom: '24px',
        },
        "& .transactionIdText": {
            color: '#334155',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            marginBottom: '24px'
        },
        "& .table": {
            minWidth: 650,
            width: '100%'
        },
        "& .firstColumn": {
            width: '15%',
            textAlign: 'left'
        },

        "& .middleColumn": {
            flexGrow: 1,
            textAlign: 'center'
        },

        "& .lastColumn": {
            width: '15%',
            textAlign: 'right'
        },
        "& .depositHistoryText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '32px',
            letterSpacing: '-0.12px',
            marginBottom: '8px'
        },
        "& .paginationRoot": {
            '& .MuiPaginationItem-root': {
                borderRadius: 4,
                marginRight: '16px'
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                border: '1px solid #94A3B8',
                color: '#94A3B8',
            },
        },
        "& .headingStyle": {
            [theme.breakpoints.down(750)]: {
                fontSize: '16px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
        },
        "& .tableContentText": {
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .tableBtn": {
            textTransform: 'none',
            width: '120px',
            padding: '12px 16px',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .amountText": {
            color: '#F59E0B',
            fontFamily: "DIN Next LT Arabic Light",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .headingFlexBox": {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '78',
        },
        "& .HistoryFlexBox": {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
        },
        "& .depositHistoryContainer": {
            marginTop: '96px',
        },
        "& .tableDeskTopView": {
            display: 'block',
            width: '100%',
            [theme.breakpoints.down(750)]: {
                display: 'none'
            },
        },
        "& .tableMobileView": {
            display: 'none',
            [theme.breakpoints.down(750)]: {
                display: 'block'
            }
        },
        "& .addIconStyle": {
            marginTop: '3px', color: "#fff",
            [theme.breakpoints.down(750)]: {
                fontSize: '24px'
            }
        },
        "& .responsiveCardContainer": {
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        "& .responsiveCard": {
            flexBasis: '600px',
            flexGrow: 1,
            minWidth: '220px',
            width: '100%',
            padding: 3,
            borderRadius: 1,
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
            },
        },
        "& .cardContent": {
            flexGrow: 1,
            padding: '8px 12px'
        },
        "& .stageCard": {
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
                width: '100%'
            },
        },
        "& .stageCardContent": {
            flexBasis: '400px',
            flexGrow: 1,
            minWidth: '160px',
            padding: '24px',
            borderRadius: '8px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
            },
        },
        "& .trasactionValueText": {
            color: "#1E293B",
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '26px',
        },
        "& .downloadBtn": {
            backgroundColor: '#10B981',
            borderRadius: '8px',
            padding: '10px',
            height: '48px',
            width: '48px',
            marginRight: '10px'
        },
        "& .deleteBtn": {
            backgroundColor: '#FEE2E2',
            borderRadius: '8px',
            padding: '10px',
            height: '48px',
            width: '48px'
        },
        "& .boxContainer": {
            width: "100%",
            position: "fixed" as "fixed",
            top: 0,
            right: 0,
            height: "100%",
            zIndex: 30,
            display: "flex",
            justifyContent: "center" as "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.6)"
        },
        "& .modalContainer": {
            [theme.breakpoints.down(938)]: {
                width: "calc(55% - 48px)",
            }, [theme.breakpoints.down(853)]: {
                width: "calc(60% - 48px)",
            }, [theme.breakpoints.down(782)]: {
                width: "calc(65% - 48px)",
            }, [theme.breakpoints.down(722)]: {
                width: "calc(70% - 48px)",
            },
            [theme.breakpoints.down(670)]: {
                width: "calc(75% - 48px)",
            },
            [theme.breakpoints.down(626)]: { width: "calc(80% - 48px)" },
            [theme.breakpoints.down(598)]: { width: "90%" },
            width: "calc(40% - 48px)"
        },


    }));

    renderPagination() {
        const { paginationData } = this.state;

        if (paginationData.total_pages <= 1) return null;

        return (
            <Box marginTop={2} display="flex" style={{justifyContent: this.handleCondition(this.props.i18n?.language === 'ar', 'flex-start', 'flex-end'), direction:"ltr"}}>
                <Pagination
                    data-test-id="pagination"
                    count={paginationData.total_pages}
                    page={paginationData.current_page}
                    onChange={(event, page) => this.handlePaginatioChange(event, page)}
                    color="primary"
                    size="small"
                    siblingCount={0}
                    boundaryCount={1}
                    className="paginationRoot"
                />
            </Box>
        );
    }

    renderAccountLetterForm() {
        const { accountLetterForm, continueDraftId, rememberMe} = this.state;
        const { accountId, accountType, dateOpened, errors } = accountLetterForm;

        return (
            <AccountLetterForm
            open={this.state.openFormModal}
            accountId={accountId}
            accountType={accountType}
            dateOpened={dateOpened}
            checkedRememberMe={rememberMe}
            errors={errors}
            handleFormInputChange={this.handleFormInputChange}
            handleRememberMe={this.handleRememberMeChange}
            handleSaveDraft={this.handleOpenConfirmDraftModal}
            handleSubmit={this.handleSubmit}
            handleClose={this.handleCloseFormModal}
            continueDraftId={continueDraftId}
        />
        )
    }

    renderConfirmSavedLetter() {
        const { confirmDraftModal } = this.state;

        return (
            <ConfirmSaveDraft
                open={confirmDraftModal}
                handleCloseModal={this.handleCloseConfirmDraftModal}
                handleDiscard={this.handleDiscardForm}
                handleSave={this.createAccountLetterRequest}
            />
        )
    }

    renderAccountLetterViewModal() {
        const { genLetterModal, createdLetterPdfLink } = this.state;

        return (
            <AccountLetterViewModal
                open={genLetterModal}
                onClose={this.handleCloseGenerateLetterModal}
                pdfUrl={createdLetterPdfLink}
                onEdit={this.getSpecificLetterRequest}
                onGenerate={this.generateAccountLetterRequest}
            />
        )
    }

    renderShowSuccessModal() {
        const { successfullyGenModal, createdLetterPdfLink } = this.state;

        return (
            <SuccessALModal
                open={successfullyGenModal}
                downloadLink={createdLetterPdfLink}
                handleCloseModal={this.handleCloseSuccessfullyGenModal}
                handledDownload={this.downloadAccLetter}
            />
        )
    }

    renderDeleteAlertModal() {
        const { deleteAlertModal, deleteItemId } = this.state;
        
        return (
            <DeleteConfirmationPopup
                open={deleteAlertModal}
                itemId={deleteItemId}
                onConfirm={this.deleteAccountLetterRequest}
                onClose={this.handleCloseDeleteAlertModal}
            />
        )
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { recentlySavedDocList, documentHistoryList, genLetterModal } = this.state;
        
        return (
            <div style={{ display: "flex", direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr') }}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation} i18n={this.props.i18n}></NavigationMenu>
                </Box>
                <this.MainWrapper>
                    <Box className="container">
                        <div style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                        {this.renderAccountLetterForm()}
                        {this.renderConfirmSavedLetter()}
                        {genLetterModal && this.renderAccountLetterViewModal()}
                        {this.renderShowSuccessModal()}
                        {this.renderDeleteAlertModal()}
                        <Box className="headingFlexBox">
                            <Typography className="headingText">{this.handleTranslationChange('accountLetter')}</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
                                <Box className="searchBarContainer">
                                    <TextField
                                        className="searchBarInput"
                                        placeholder={this.handleTranslationChange('typeToSearch')}
                                        InputProps={{
                                            startAdornment: (<img src={searchIcon} alt={'icon'} className="icon" />),
                                            style: {
                                                padding: '5px',
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                                <img src={searchIcon} className="mobileSearch" />

                                <Button
                                    data-test-id="showAddBtn"
                                    onClick={this.handleGenerateBtnFunc}
                                    startIcon={<AddCircleOutlineIcon
                                        className="addIconStyle" />}
                                    className="customButton">
                                    <span className="buttonText" style={{padding:"0px 5px"}}>{this.handleTranslationChange('generate_acc_text')}</span>
                                </Button>
                            </Box>
                        </Box>

                        <Box>
                            <Typography className="newRequestText">{this.handleTranslationChange('recently_saved')}</Typography>
                            <TableContainer className="tableDeskTopView">
                                <Table className="table" aria-label="simple table">
                                    <TableHead style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableRow>
                                            <TableCell className="headingStyle firstColumn" >
                                                {this.handleTranslationChange('unique_id')}</TableCell>
                                            <TableCell 
                                            className="headingStyle middleColumn" >
                                                {this.handleTranslationChange('account_type')}
                                                </TableCell>
                                            <TableCell className="headingStyle middleColumn" >
                                                  {this.handleTranslationChange('last_modified')}</TableCell>
                                            <TableCell className="headingStyle middleColumn">
                                                     {this.handleTranslationChange('status')}    
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.handleCondition(recentlySavedDocList.length === 0
                                         ,
                                         <Typography style={{ width: '100%', textAlign: 'center' }}
                                          className="tableContentText">{this.handleTranslationChange('noDataFound')}</Typography>, "")}
                                        {recentlySavedDocList.map((row) => {
                                            const bgColor = this.handleCondition(row.status === 'Inactive' , '#FFCCCC' , '#1B4FE4')
                                            const color = this.handleCondition(row.status === 'Inactive' , '#EF4444' , '#fff')
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell className="tableContentText firstColumn" >
                                                        {row.account_unique_id}
                                                    </TableCell>
                                                    <TableCell className="tableContentText middleColumn" >{row.accountType}</TableCell>
                                                    <TableCell className="tableContentText middleColumn" >{row.lastModified}</TableCell>
                                                    <TableCell className="stepFont middleColumn" >
                                                        <Button
                                                            data-test-id="continueBtn"
                                                            onClick={() => this.handleContinueDraftLetter(row.id)}
                                                            style={{ color, backgroundColor: bgColor }} className="tableBtn">
                                                            {capitalizeFirstLetters(row.status)}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box className="tableMobileView">
                                <Box
                                    className="responsiveCardContainer"
                                >
                                    {recentlySavedDocList.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">{this.handleTranslationChange('noDataFound')}</Typography>}
                                    {recentlySavedDocList?.map((row, index) => {
                                        const bgColor = row.status === 'Inactive' ? '#FFCCCC' : '#1B4FE4'
                                        const color = row.status === 'Inactive' ? '#EF4444' : '#fff'
                                        return (
                                            <Card elevation={0} key={index} className="responsiveCard">
                                                <Box
                                                    className="cardContent"
                                                    style={{
                                                        backgroundColor: (index % 2 === 0) ? '#F1F5F9' : 'none',
                                                    }}
                                                >
                                                    {['Unique ID', 'Account Type', 'Last Modified', 'Status'].map((label, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                                                        >
                                                            <Typography className="headingStyle">{label}</Typography>
                                                            <Typography className="tableContentText">
                                                                {label === 'Unique ID' && row.account_unique_id}
                                                                {label === 'Account Type' && row.accountType}
                                                                {label === 'Last Modified' && row.lastModified}
                                                                {label === 'Status' && (
                                                                    <Box>
                                                                        <Button
                                                                            data-test-id="continueBtn"
                                                                            onClick={() => this.handleContinueDraftLetter(row.id)}
                                                                            style={{ color, backgroundColor: bgColor }} className="tableBtn">
                                                                            {capitalizeFirstLetters(row.status)}
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Card>
                                        );
                                    })}
                                </Box>
                            </Box>

                        <Box className="depositHistoryContainer">
                            <Box className="HistoryFlexBox">
                                <Typography className="depositHistoryText">{this.handleTranslationChange('document_history')}</Typography>
                                <SortingAlDropdown handleSorting={this.handleSortingChange}/>
                            </Box>
                            <TableContainer className="tableDeskTopView">
                                <Table className="table" aria-label="simple table">
                                    <TableHead style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableRow>
                                            <TableCell 
                                            className="headingStyle firstColumn">
                                        {this.handleTranslationChange('name')}
                                                </TableCell>
                                            <TableCell className="headingStyle middleColumn" >
                                                        {this.handleTranslationChange('type')}  </TableCell>
                                                <TableCell className="headingStyle middleColumn" >  {this.handleTranslationChange('date')}  </TableCell>
                                                <TableCell className="headingStyle middleColumn" >{     
                                            this.handleTranslationChange('size')
                                            }</TableCell>
                                                <TableCell className="headingStyle middleColumn" >{
                                            this.handleTranslationChange('status')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documentHistoryList.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">{this.handleTranslationChange('noDataFound')}</Typography>}
                                        {documentHistoryList?.map((row) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell className="tableContentText firstColumn" >
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell className="tableContentText middleColumn" >{row.type}</TableCell>
                                                    <TableCell className="tableContentText middleColumn" >{row.lastModified}</TableCell>
                                                    <TableCell className="tableContentText middleColumn" >{row.size}</TableCell>
                                                    <TableCell className="stepFont middleColumn">
                                                        <Box style={{display:"flex", justifyContent: 'center', gap: this.handleCondition(this.props.i18n.language === 'ar', '12px', '')}}>
                                                        <IconButton data-test-id="downloadBtn" className="downloadBtn" onClick={() => {handleDownload(row.url, "Account Letter.pdf"); this.downloadAccLetter2(row.id)}}>
                                                            <img src={downloadSmallIcon} alt="download" />
                                                        </IconButton>
                                                        <IconButton data-test-id="deleteBtn" className="deleteBtn" onClick={() => this.handleOpenDeleteAlertModal(row.id)}>
                                                            <img src={deleteSmallIcon} alt="delete" />
                                                        </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                {
                                    this.renderPagination()
                                }
                            </TableContainer>
                            <Box className="tableMobileView">
                                <Box
                                    className="responsiveCardContainer"
                                >
                                    {documentHistoryList.length === 0 && <Typography style={{ width: '100%', textAlign: 'center' }} className="tableContentText">No data found</Typography>}
                                    {documentHistoryList?.map((row, index) => {
                                        return (
                                            <Card elevation={0} key={index} className="responsiveCard">
                                                <Box
                                                    className="cardContent"
                                                    style={{
                                                        backgroundColor: (index % 2 === 0) ? '#F1F5F9' : 'none',
                                                    }}
                                                >
                                                    {['Name', 'Type', 'Date', 'Size', 'Status'].map((label, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                                                        >
                                                            <Typography className="headingStyle">{label}</Typography>
                                                            <Typography className="tableContentText">
                                                                {label === 'Name' && row.name}
                                                                {label === 'Type' && row.type}
                                                                {label === 'Date' && row.lastModified}
                                                                {label === 'Size' && row.size}

                                                                {label === 'Status' && (
                                                                    <Box>
                                                                        <IconButton data-test-id="downloadBtnMb"
                                                                            onClick={() => handleDownload(row.url, "Account Letter.pdf")}
                                                                            className="downloadBtn">
                                                                            <img src={downloadSmallIcon} alt="download" />
                                                                        </IconButton>
                                                                        <IconButton data-test-id="deleteBtnMb"
                                                                            onClick={() => this.handleOpenDeleteAlertModal(row.id)}
                                                                            className="deleteBtn">
                                                                            <img src={deleteSmallIcon} alt="delete" />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Card>
                                        );
                                    })}
                                </Box>
                                {
                                    this.renderPagination()
                                }
                            </Box>
                        </Box>
                        </div>
                    </Box>
                </this.MainWrapper>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export const AccountLetterWebWithTranslation = withTranslation()(DepositRequest)
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


// Customizable Area End
