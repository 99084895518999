// Customizable Area Start
import React from "react";
import QuestionBankController from "./QuestionBankController.web";
import {
  createTheme,
  styled,
  Box,
  ThemeProvider,
  Typography,
  Button,
  Radio,
  LinearProgress,
} from "@material-ui/core";
import { crossIcon, imgLogo, leftArrow, leftArrow2, radioIcon, rightArrow } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

export default class QuestionBank extends QuestionBankController {
  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .container": {
      [theme.breakpoints.down(598)]: {
        flexDirection: "column",
        // height: "100%",
      },
      display: "flex",
      width: "100vw",
      height: "100vh",
      overflow:"hidden"
    },
    "& .leftSide": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        height: "500px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#122967",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: "#143288",
      width: "293px",
      height: "auto",
      // maxHeight: "fit-content",
      // minHeight: "100%"
    },
    "& .rightSide": {
      [theme.breakpoints.down(598)]: {
        width: "calc(100% - 40px)",
        height: "fit-content",
        padding: "0 20px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "24px 24px 0 0",
        position: "relative",
        top: "-20px",
        backgroundColor: "white",
      },
      width: "calc(100% - 293px)",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "80px",
      overflowY:'scroll',
    },
    "& .maincontainer": {
      [theme.breakpoints.down(598)]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      },
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& .headingBox": {
      [theme.breakpoints.down(598)]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column-reverse",
        paddingTop: "20px",
        paddingBottom: "20px",
      },
      display: "flex",
      paddingTop: "60px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "32px",
      flexDirection: "column",
      width: "100%",
    },
    "& .heading": {
      [theme.breakpoints.down(598)]: {
        fontSize: "24px",
        color: "#122967",
      },
      fontSize: "36px",
      fontFamily: "DIN Next LT Arabic Bold",
      textAlign: "left",
    },
    "& .questionBox": {
      [theme.breakpoints.down(598)]: {
        gap: "12px",
        marginTop: "0",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
      flexDirection: "column",
      width: "100%",
      marginTop: "50px",
    },
    "& .buttonBox": {
      [theme.breakpoints.down(598)]: {
        justifyContent: "space-between",
        paddingTop: "10px",
      },
      [theme.breakpoints.down(827)]: {
        gap: "0",
      },
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "40px",
      paddingTop: "60px",
    },
    "& .question": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Bold",
        color: "#334155",
      },
      fontSize: "30px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#0F172A",
    },
    "& .previous": {
      [theme.breakpoints.down(598)]: {
        color: "#122967",
      },
      fontSize: "16px",
      color: "#64748B",
      fontFamily: "DIN Next LT Arabic Bold",
      textTransform: "capitalize",
      padding: "10px 16px"
    },
    "& .next": {
      [theme.breakpoints.down(598)]: {
        width: "50%",
        justifyContent: "center",
        borderRadius: "8px",
        backgroundColor: "#122967",
        fontFamily: "DIN Next LT Arabic Light",
      },
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      padding: "10px 45px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      textTransform: "capitalize"
    },
    "& .nextForMobile": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Regular",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#122967",
        color: "#FFFFFF",
      },
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#FFFFFF",
      backgroundColor: "#1B4FE4",
      padding: "10px 45px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      textTransform: "capitalize"
    },
    "& .previousBox": {
      [theme.breakpoints.down(598)]: {
        width: "50%",
        justifyContent: "flex-start",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
      "& .checked": {
        [theme.breakpoints.down(598)]: {},
        boxShadow: "none",
        width: '1.5rem',
        height: '1.5rem',
        minWidth: "20px",
        minHeight: "20px",
        maxWidth: "20px",
        maxHeight: "20px",
        borderRadius: "50%",
        border: "1px solid #64748B",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      "& .clicked": {
      [theme.breakpoints.down(598)]: {},
      backgroundColor: "#132E79",
      width: '1.5rem',
      height: '1.5rem',
      minWidth: "20px",
      minHeight: "20px",
      maxWidth: "20px",
      maxHeight: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      border: "none",
    },
    "& .empty": {
      [theme.breakpoints.down(598)]: {},
      backgroundColor: "white",
      width: "4px",
      height: "4px",
      borderRadius: "50%",
    },
    "& .answerBox": {
      [theme.breakpoints.down(598)]: {},
      [theme.breakpoints.down(598)]: {
        borderRadius: "8px",
        maxWidth: "100%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.between(598, 650)]: {
        width: '100%'
      },
      width: "calc(100% - 32px)",
      maxWidth: "80%",
      borderRadius: "12px",
      padding: "10px 16px",
      border: "1px solid #D6D3D1",
      boxShadow: "0px 2px 8px 0px #00000014",
      display: "flex",
      alignItems: "center",
      gap: "12px",
      justifyContent: "flex-start",
    },
    "& .answer": {
      [theme.breakpoints.down(598)]: {
        fontSize: "16px",
        fontFamily: "DIN Next LT Arabic Regular",
        // color: "#0F172A",
      },
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Regular",
      // color: "#0F172A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .answerContainer": {
      [theme.breakpoints.down(598)]: {
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      },
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
      flexDirection: "column",
      width: "70%",
    },
    "& .progressBar": {
      [theme.breakpoints.down(598)]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "75%",
      },
      width: "100%",
      maxWidth: "58%",
    },
    "& .leftArrow": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
    },
    "& .leftArrow2": {
      [theme.breakpoints.up(598)]: {
        display: "none",
      },
    },
    "& .exclamatory": {
      [theme.breakpoints.down(598)]: {
        display: "none",
      },
    },
    "& .progressContainer": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
      },
      width: "70%",
      height: "20%",
    },
    "& .successPopupContainer": {
      width: "100%",
      position: "fixed" as "fixed",
      top: 0,
      right: 0,
      height: "100%",
      zIndex: 30,
      display: "flex",
      justifyContent: "center" as "center",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.6)",
    },
    "& .successBox": {
      [theme.breakpoints.down(598)]: {
        width: "80%" ,
      },
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "24px",
      backgroundColor: "#FFF",
      top: 0,
      zIndex: 31,
      width: "35%",
      boxShadow:
        "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    },
    "& .iconBox": {
      width: "95%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", paddingTop: "20px"
    },
    "& .successBox2": {
      padding: "10px 40px 15px 40px",
      display: "flex",
      flexDirection: "column",
      gap: "16px"
    },
    "& .successBox22": {
      padding: "10px 40px 15px 40px",
      display: "flex",
      flexDirection: "column",
      gap: "24px"
    },
    "& .surefor": {
     fontSize: "24px",
     fontFamily: "DIN Next LT Arabic Bold",
    },
    "& .backfor": {
     fontSize: "18px",
     fontFamily: "DIN Next LT Arabic Regular",
    },
    "& .viewBox": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "15px",
    padding: "20px",
    width: "calc(100% - 40px)",
    borderTop: "1px solid #E2E8F0"
    },
    "& .viewStatus": {
      [theme.breakpoints.down(1560)]: {
        width: "40%" ,
      },
      [theme.breakpoints.down(1165)]: {
        width: "50%" ,
      },
      [theme.breakpoints.down(950)]: {
        width: "60%" ,
      },
      [theme.breakpoints.down(803)]: {
        width: "70%" ,
      },
      [theme.breakpoints.down(690)]: {
        width: "80%" ,
      },
      [theme.breakpoints.down(598)]: {
        width: "100%" ,
      },
      padding: "16px 0 16px 0",
      paddingTop: "16px",
      borderRadius: "8px",
      border: "1.5px solid #1B4FE4",
      background: "#1B4FE4",
      color: "#FFFFFF",
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      width: "30%",
    }
  }));

  render() {
    return (
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          <Box className="container" >
            {this.state.totalCheckPopup ? (
              <Box className="successPopupContainer">
              <Box className="successBox" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
              <Box className="successBox22">
                <Typography className="surefor">{this.handleTranslationChange('alert')}!</Typography>
                <Typography className="backfor">{this.handleTranslationChange('selectAllAnswer')}</Typography>
              </Box>
              <Box className="viewBox">
                <button className="viewStatus" onClick={this.handleAllCheck}>{this.handleTranslationChange('ok')}</button>
              </Box>
              </Box>
            </Box> 
            ) : ""}
            {this.state.successPopup && (
              <Box className="successPopupContainer">
                <Box className="successBox" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                <Box className="iconBox">
                  <img data-test-id="successPopup" src={crossIcon}
                   onClick={this.handleSuccessPopup}
                  />
                </Box>
                <Box className="successBox2">
                  <Typography className="surefor">{this.handleTranslationChange('thank_you')}</Typography>
                  <Typography className="backfor">{this.handleTranslationChange('thank_you_msg')}</Typography>
                </Box>
                <Box className="viewBox">
                  <button data-test-id="successId"
                   className="viewStatus"
                    onClick={() => this.handleNavigate()}>{this.handleTranslationChange('back_to_home')}</button>
                </Box>
                </Box>
              </Box>
            )}
            <Box className="leftSide">
              <img src={imgLogo} alt="img!" />
            </Box>
            <Box className="rightSide">
              <Box className="maincontainer" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                <Box className="headingBox">
                  <Typography className="heading">
                  {this.handleTranslationChange('ques_bank_heading')}{" "}
                  </Typography>
                  <Box className="progressContainer">
                    <ProgressBar
                      variant="determinate"
                      value={(this.state.dataArray.filter((item) => String(item.question_option_id) !== "").length * 100)/ this.state.dataArray.length}
                      style={{transform: this.handleCondition(this.props.i18n?.language === "ar", "rotateY(180deg)", "")}}     
                    />
                  </Box>
                </Box>
                <Box className="questionBox">
                  <Typography className="question">
                    {this.state.activeData.first + 1}{". "}
                    {
                      this.state.questionArr?.[this.state.activeData.first]?.attributes?.question_description
                    }
                  </Typography>
                  <Box className="answerContainer">
                    {this.state.questionArr?.[this.state.activeData.first]?.attributes?.question_options?.data?.map((item: {attributes: {id: number, option: string, question_bank_id: number}}, index: number) => (
                        <Box className="answerBox" key={index}>
                          <Box
                            data-test-id={`radio${index}`}
                            className={`checked ${Number(this.state.dataArray?.[this.state.activeData.first]?.question_option_id) ===
                             Number(item?.attributes?.id) ? "" : ''}`}
                            onClick={() => this.handleOptionClick(item)}
                          >
                            {(Number(this.state.dataArray?.[this.state.activeData.first]?.question_option_id) ===
                               Number(item?.attributes?.id)) ? <img src={radioIcon} /> : ""}
                          </Box>
                          <Typography key={item?.attributes?.id} className="answer"
                           style={{color: Number(this.state.dataArray?.[this.state.activeData.first]?.question_option_id) === Number(item?.attributes?.id)
                            ? "#0F172A" : "#A6B0BF"}}>                  
                            {item?.attributes?.option}
                          </Typography>
                        </Box>
                    ))}
                  </Box>
                </Box>
                <Box className="buttonBox">
                  {this.state.activeData.first !== 0 && (
                    <Box className="previousBox"  onClick={this.handlePrevious}>
                      <img src={this.handleCondition(this.props.i18n?.language === 'ar', rightArrow, leftArrow)} alt="Image" className="leftArrow" />
                      <img
                        src={leftArrow2}
                        alt="Image"
                        className="leftArrow2"
                      />
                      <Button
                        className="previous"
                       
                      >
                        {this.handleTranslationChange('previous')}
                      </Button>
                    </Box>
                  )}
                  <Button
                    data-test-id="questionBankPost"
                    className={
                      this.state.activeData.first !== 0
                        ? "next"
                        : "nextForMobile"
                    }
                    onClick={this.state.activeData.first === this.state.questionArr?.length - 1 
                    ? this.handleSelectOption
                    : this.handleNext}
                  >
                    {this.state.activeData.first === this.state.questionArr?.length - 1 
                    ? <Box>{this.handleTranslationChange('submit')}</Box> 
                    : <Box>{this.handleTranslationChange('next')}</Box> }
                  </Button>
                </Box>
              </Box>

            </Box>
          </Box>
        </ThemeProvider>
      </this.MainWrapper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export const QuestionBankWebWithTranslation = withTranslation()(QuestionBank)
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
        fontWeight: 500,
    },
    subtitle1: {
        margin: "20px 0px",
    },
},
});

const ProgressBar = styled(LinearProgress)({
  "&.MuiLinearProgress-root": {
    height: "12px",
    borderRadius: "50px",
  },
  
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#143288",
  },
  "& .PrivateRadioButtonIcon-layer-22": {
    color: "red"
  },
});
// Customizable Area End
