Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.deactivateAccApiEndPoint = "bx_block_profile/profiles";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getUserDetailsApi = "account_block/accounts/get_profile"
exports.userDetailsUpdateApi = "account_block/accounts/update_profile"
exports.userProfileImageUpdateApi = "account_block/accounts/update_profile_image"
exports.updateLanguageEndpoint = "bx_block_language_options/languages/update_language"
exports.currencyListEndpoint = "bx_block_settings/settings/currency_listing"
exports.selectedCurrencyEndpoint = "bx_block_settings/settings/user_selected_currency"
exports.updateCurrencyEndpoint = "bx_block_settings/settings/update_currency"
exports.faqEndpoint = "bx_block_landingpage2/about_us"
exports.enableAllNotification = "bx_block_settings/settings/update_notification_setting"
exports.countryList = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo (Congo-Brazzaville)", label: "Congo (Congo-Brazzaville)" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "East Timor (Timor-Leste)", label: "East Timor (Timor-Leste)" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini (fmr. \"Swaziland\")", label: "Eswatini (fmr. \"Swaziland\")" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Ivory Coast", label: "Ivory Coast" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar (formerly Burma)", label: "Myanmar (formerly Burma)" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine State", label: "Palestine State" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Korea", label: "South Korea" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States of America", label: "United States of America" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

exports.countryListAr = [
  { value: "Afghanistan", label: "أفغانستان" },
  { value: "Albania", label: "ألبانيا" },
  { value: "Algeria", label: "الجزائر" },
  { value: "Andorra", label: "أندورا" },
  { value: "Angola", label: "أنغولا" },
  { value: "Antigua and Barbuda", label: "أنتيغوا وبربودا" },
  { value: "Argentina", label: "الأرجنتين" },
  { value: "Armenia", label: "أرمينيا" },
  { value: "Australia", label: "أستراليا" },
  { value: "Austria", label: "النمسا" },
  { value: "Azerbaijan", label: "أذربيجان" },
  { value: "Bahamas", label: "جزر البهاما" },
  { value: "Bahrain", label: "البحرين" },
  { value: "Bangladesh", label: "بنغلاديش" },
  { value: "Barbados", label: "بربادوس" },
  { value: "Belarus", label: "بيلاروسيا" },
  { value: "Belgium", label: "بلجيكا" },
  { value: "Belize", label: "بليز" },
  { value: "Benin", label: "بنين" },
  { value: "Bhutan", label: "بوتان" },
  { value: "Bolivia", label: "بوليفيا" },
  { value: "Bosnia and Herzegovina", label: "البوسنة والهرسك" },
  { value: "Botswana", label: "بوتسوانا" },
  { value: "Brazil", label: "البرازيل" },
  { value: "Brunei", label: "بروناي" },
  { value: "Bulgaria", label: "بلغاريا" },
  { value: "Burkina Faso", label: "بوركينا فاسو" },
  { value: "Burundi", label: "بوروندي" },
  { value: "Cabo Verde", label: "الرأس الأخضر" },
  { value: "Cambodia", label: "كمبوديا" },
  { value: "Cameroon", label: "الكاميرون" },
  { value: "Canada", label: "كندا" },
  { value: "Central African Republic", label: "جمهورية أفريقيا الوسطى" },
  { value: "Chad", label: "تشاد" },
  { value: "Chile", label: "تشيلي" },
  { value: "China", label: "الصين" },
  { value: "Colombia", label: "كولومبيا" },
  { value: "Comoros", label: "جزر القمر" },
  { value: "Congo (Congo-Brazzaville)", label: "الكونغو (برازافيل)" },
  { value: "Costa Rica", label: "كوستاريكا" },
  { value: "Croatia", label: "كرواتيا" },
  { value: "Cuba", label: "كوبا" },
  { value: "Cyprus", label: "قبرص" },
  { value: "Czech Republic", label: "جمهورية التشيك" },
  { value: "Democratic Republic of the Congo", label: "جمهورية الكونغو الديمقراطية" },
  { value: "Denmark", label: "الدنمارك" },
  { value: "Djibouti", label: "جيبوتي" },
  { value: "Dominica", label: "دومينيكا" },
  { value: "Dominican Republic", label: "جمهورية الدومينيكان" },
  { value: "East Timor (Timor-Leste)", label: "تيمور الشرقية (تيمور ليستيه)" },
  { value: "Ecuador", label: "الإكوادور" },
  { value: "Egypt", label: "مصر" },
  { value: "El Salvador", label: "السلفادور" },
  { value: "Equatorial Guinea", label: "غينيا الاستوائية" },
  { value: "Eritrea", label: "إريتريا" },
  { value: "Estonia", label: "إستونيا" },
  { value: "Eswatini (fmr. \"Swaziland\")", label: "إسواتيني (سوازيلاند سابقاً)" },
  { value: "Ethiopia", label: "إثيوبيا" },
  { value: "Fiji", label: "فيجي" },
  { value: "Finland", label: "فنلندا" },
  { value: "France", label: "فرنسا" },
  { value: "Gabon", label: "الغابون" },
  { value: "Gambia", label: "غامبيا" },
  { value: "Georgia", label: "جورجيا" },
  { value: "Germany", label: "ألمانيا" },
  { value: "Ghana", label: "غانا" },
  { value: "Greece", label: "اليونان" },
  { value: "Grenada", label: "غرينادا" },
  { value: "Guatemala", label: "غواتيمالا" },
  { value: "Guinea", label: "غينيا" },
  { value: "Guinea-Bissau", label: "غينيا بيساو" },
  { value: "Guyana", label: "غيانا" },
  { value: "Haiti", label: "هايتي" },
  { value: "Honduras", label: "هندوراس" },
  { value: "Hungary", label: "المجر" },
  { value: "Iceland", label: "آيسلندا" },
  { value: "India", label: "الهند" },
  { value: "Indonesia", label: "إندونيسيا" },
  { value: "Iran", label: "إيران" },
  { value: "Iraq", label: "العراق" },
  { value: "Ireland", label: "أيرلندا" },
  { value: "Israel", label: "إسرائيل" },
  { value: "Italy", label: "إيطاليا" },
  { value: "Ivory Coast", label: "ساحل العاج" },
  { value: "Jamaica", label: "جامايكا" },
  { value: "Japan", label: "اليابان" },
  { value: "Jordan", label: "الأردن" },
  { value: "Kazakhstan", label: "كازاخستان" },
  { value: "Kenya", label: "كينيا" },
  { value: "Kiribati", label: "كيريباتي" },
  { value: "Kosovo", label: "كوسوفو" },
  { value: "Kuwait", label: "الكويت" },
  { value: "Kyrgyzstan", label: "قيرغيزستان" },
  { value: "Laos", label: "لاوس" },
  { value: "Latvia", label: "لاتفيا" },
  { value: "Lebanon", label: "لبنان" },
  { value: "Lesotho", label: "ليسوتو" },
  { value: "Liberia", label: "ليبيريا" },
  { value: "Libya", label: "ليبيا" },
  { value: "Liechtenstein", label: "ليختنشتاين" },
  { value: "Lithuania", label: "ليتوانيا" },
  { value: "Luxembourg", label: "لوكسمبورغ" },
  { value: "Madagascar", label: "مدغشقر" },
  { value: "Malawi", label: "مالاوي" },
  { value: "Malaysia", label: "ماليزيا" },
  { value: "Maldives", label: "المالديف" },
  { value: "Mali", label: "مالي" },
  { value: "Malta", label: "مالطا" },
  { value: "Marshall Islands", label: "جزر مارشال" },
  { value: "Mauritania", label: "موريتانيا" },
  { value: "Mauritius", label: "موريشيوس" },
  { value: "Mexico", label: "المكسيك" },
  { value: "Micronesia", label: "ميكرونيزيا" },
  { value: "Moldova", label: "مولدوفا" },
  { value: "Monaco", label: "موناكو" },
  { value: "Mongolia", label: "منغوليا" },
  { value: "Montenegro", label: "الجبل الأسود" },
  { value: "Morocco", label: "المغرب" },
  { value: "Mozambique", label: "موزمبيق" },
  { value: "Myanmar (Burma)", label: "ميانمار (بورما)" },
  { value: "Namibia", label: "ناميبيا" },
  { value: "Nauru", label: "ناورو" },
  { value: "Nepal", label: "نيبال" },
  { value: "Netherlands", label: "هولندا" },
  { value: "New Zealand", label: "نيوزيلندا" },
  { value: "Nicaragua", label: "نيكاراغوا" },
  { value: "Niger", label: "النيجر" },
  { value: "Nigeria", label: "نيجيريا" },
  { value: "North Korea", label: "كوريا الشمالية" },
  { value: "North Macedonia", label: "مقدونيا الشمالية" },
  { value: "Norway", label: "النرويج" },
  { value: "Oman", label: "عُمان" },
  { value: "Pakistan", label: "باكستان" },
  { value: "Palau", label: "بالاو" },
  { value: "Palestine State", label: "دولة فلسطين" },
  { value: "Panama", label: "بنما" },
  { value: "Papua New Guinea", label: "بابوا غينيا الجديدة" },
  { value: "Paraguay", label: "باراغواي" },
  { value: "Peru", label: "بيرو" },
  { value: "Philippines", label: "الفلبين" },
  { value: "Poland", label: "بولندا" },
  { value: "Portugal", label: "البرتغال" },
  { value: "Qatar", label: "قطر" },
  { value: "Romania", label: "رومانيا" }, 
  { value: "Russia", label: "روسيا" }, 
  { value: "Rwanda", label: "رواندا" }, 
  { value: "Saint Kitts and Nevis", label: "سانت كيتس ونيفيس" }, 
  { value: "Saint Lucia", label: "سانت لوسيا" }, 
  { value: "Saint Vincent and the Grenadines", label: "سانت فينسنت والغرينادين" }, 
  { value: "Samoa", label: "ساموا" }, 
  { value: "San Marino", label: "سان مارينو" }, 
  { value: "Sao Tome and Principe", label: "ساو تومي وبرينسيب" }, 
  { value: "Saudi Arabia", label: "المملكة العربية السعودية" }, 
  { value: "Senegal", label: "السنغال" }, 
  { value: "Serbia", label: "صربيا" }, 
  { value: "Seychelles", label: "سيشل" }, 
  { value: "Sierra Leone", label: "سيراليون" }, 
  { value: "Singapore", label: "سنغافورة" }, 
  { value: "Slovakia", label: "سلوفاكيا" }, 
  { value: "Slovenia", label: "سلوفينيا" }, 
  { value: "Solomon Islands", label: "جزر سليمان" }, 
  { value: "Somalia", label: "الصومال" }, 
  { value: "South Africa", label: "جنوب أفريقيا" }, 
  { value: "South Korea", label: "كوريا الجنوبية" }, 
  { value: "South Sudan", label: "جنوب السودان" }, 
  { value: "Spain", label: "إسبانيا" }, 
  { value: "Sri Lanka", label: "سريلانكا" }, 
  { value: "Sudan", label: "السودان" }, 
  { value: "Suriname", label: "سورينام" }, 
  { value: "Sweden", label: "السويد" }, 
  { value: "Switzerland", label: "سويسرا" }, 
  { value: "Syria", label: "سوريا" }, 
  { value: "Tajikistan", label: "طاجيكستان" }, 
  { value: "Tanzania", label: "تنزانيا" }, 
  { value: "Thailand", label: "تايلاند" }, 
  { value: "Togo", label: "توغو" }, 
  { value: "Tonga", label: "تونغا" }, 
  { value: "Trinidad and Tobago", label: "ترينيداد وتوباغو" }, 
  { value: "Tunisia", label: "تونس" }, 
  { value: "Turkey", label: "تركيا" }, 
  { value: "Turkmenistan", label: "تركمانستان" }, 
  { value: "Tuvalu", label: "توفالو" }, 
  { value: "Uganda", label: "أوغندا" }, 
  { value: "Ukraine", label: "أوكرانيا" }, 
  { value: "United Arab Emirates", label: "الإمارات العربية المتحدة" }, 
  { value: "United Kingdom", label: "المملكة المتحدة" }, 
  { value: "United States of America", label: "الولايات المتحدة الأمريكية" }, 
  { value: "Uruguay", label: "أوروغواي" }, 
  { value: "Uzbekistan", label: "أوزبكستان" }, 
  { value: "Vanuatu", label: "فانواتو" }, 
  { value: "Vatican City", label: "مدينة الفاتيكان" }, 
  { value: "Venezuela", label: "فنزويلا" }, 
  { value: "Vietnam", label: "فيتنام" }, 
  { value: "Yemen", label: "اليمن" }, 
  { value: "Zambia", label: "زامبيا" }, 
  { value: "Zimbabwe", label: "زيمبابوي" }
];
exports.idProof = [
  { label: "Passport", value: "Passport" },
  { label: "Birth certificate", value: "Birth certificate" },
  { label: "Driving license", value: "Driving license" },
  { label: "National ID", value: "National ID" },
];
exports.idProofAr = [
  { label: "جواز السفر", value: "Passport" },
  { label: "شهادة الميلاد", value: "Birth certificate" },
  { label: "رخصة القيادة", value: "Driving license" },
  { label: "الهوية الوطنية", value: "National ID" },
];
exports.countryCodeList = [
  { label: "+93", value: "+93" },
  { label: "+355", value: "+355" },
  { label: "+213", value: "+213" },
  { label: "+376", value: "+376" },
  { label: "+244", value: "+244" },
  { label: "+1-268", value: "+1-268" },
  { label: "+54", value: "+54" },
  { label: "+374", value: "+374" },
  { label: "+61", value: "+61" },
  { label: "+43", value: "+43" },
  { label: "+994", value: "+994" },
  { label: "+1-242", value: "+1-242" },
  { label: "+973", value: "+973" },
  { label: "+880", value: "+880" },
  { label: "+1-246", value: "+1-246" },
  { label: "+375", value: "+375" },
  { label: "+32", value: "+32" },
  { label: "+501", value: "+501" },
  { label: "+229", value: "+229" },
  { label: "+975", value: "+975" },
  { label: "+591", value: "+591" },
  { label: "+387", value: "+387" },
  { label: "+267", value: "+267" },
  { label: "+55", value: "+55" },
  { label: "+673", value: "+673" },
  { label: "+359", value: "+359" },
  { label: "+226", value: "+226" },
  { label: "+257", value: "+257" },
  { label: "+238", value: "+238" },
  { label: "+855", value: "+855" },
  { label: "+237", value: "+237" },
  { label: "+1", value: "+1" },
  { label: "+236", value: "+236" },
  { label: "+235", value: "+235" },
  { label: "+56", value: "+56" },
  { label: "+86", value: "+86" },
  { label: "+57", value: "+57" },
  { label: "+269", value: "+269" },
  { label: "+242", value: "+242" },
  { label: "+506", value: "+506" },
  { label: "+385", value: "+385" },
  { label: "+53", value: "+53" },
  { label: "+357", value: "+357" },
  { label: "+420", value: "+420" },
  { label: "+243", value: "+243" },
  { label: "+45", value: "+45" },
  { label: "+253", value: "+253" },
  { label: "+1-767", value: "+1-767" },
  { label: "+1-809", value: "+1-809" },
  { label: "+670", value: "+670" },
  { label: "+593", value: "+593" },
  { label: "+20", value: "+20" },
  { label: "+503", value: "+503" },
  { label: "+240", value: "+240" },
  { label: "+291", value: "+291" },
  { label: "+372", value: "+372" },
  { label: "+268", value: "+268" },
  { label: "+251", value: "+251" },
  { label: "+679", value: "+679" },
  { label: "+358", value: "+358" },
  { label: "+33", value: "+33" },
  { label: "+241", value: "+241" },
  { label: "+220", value: "+220" },
  { label: "+995", value: "+995" },
  { label: "+49", value: "+49" },
  { label: "+233", value: "+233" },
  { label: "+30", value: "+30" },
  { label: "+1-473", value: "+1-473" },
  { label: "+502", value: "+502" },
  { label: "+224", value: "+224" },
  { label: "+245", value: "+245" },
  { label: "+592", value: "+592" },
  { label: "+509", value: "+509" },
  { label: "+504", value: "+504" },
  { label: "+36", value: "+36" },
  { label: "+354", value: "+354" },
  { label: "+91", value: "+91" },
  { label: "+62", value: "+62" },
  { label: "+98", value: "+98" },
  { label: "+964", value: "+964" },
  { label: "+353", value: "+353" },
  { label: "+972", value: "+972" },
  { label: "+39", value: "+39" },
  { label: "+225", value: "+225" },
  { label: "+1-876", value: "+1-876" },
  { label: "+81", value: "+81" },
  { label: "+962", value: "+962" },
  { label: "+7", value: "+7" },
  { label: "+254", value: "+254" },
  { label: "+686", value: "+686" },
  { label: "+383", value: "+383" },
  { label: "+965", value: "+965" },
  { label: "+996", value: "+996" },
  { label: "+856", value: "+856" },
  { label: "+371", value: "+371" },
  { label: "+961", value: "+961" },
  { label: "+266", value: "+266" },
  { label: "+231", value: "+231" },
  { label: "+218", value: "+218" },
  { label: "+423", value: "+423" },
  { label: "+370", value: "+370" },
  { label: "+352", value: "+352" },
  { label: "+261", value: "+261" },
  { label: "+265", value: "+265" },
  { label: "+60", value: "+60" },
  { label: "+960", value: "+960" },
  { label: "+223", value: "+223" },
  { label: "+356", value: "+356" },
  { label: "+692", value: "+692" },
  { label: "+222", value: "+222" },
  { label: "+230", value: "+230" },
  { label: "+52", value: "+52" },
  { label: "+691", value: "+691" },
  { label: "+373", value: "+373" },
  { label: "+377", value: "+377" },
  { label: "+976", value: "+976" },
  { label: "+382", value: "+382" },
  { label: "+212", value: "+212" },
  { label: "+258", value: "+258" },
  { label: "+95", value: "+95" },
  { label: "+264", value: "+264" },
  { label: "+674", value: "+674" },
  { label: "+977", value: "+977" },
  { label: "+31", value: "+31" },
  { label: "+64", value: "+64" },
  { label: "+505", value: "+505" },
  { label: "+227", value: "+227" },
  { label: "+234", value: "+234" },
  { label: "+850", value: "+850" },
  { label: "+389", value: "+389" },
  { label: "+47", value: "+47" },
  { label: "+968", value: "+968" },
  { label: "+92", value: "+92" },
  { label: "+680", value: "+680" },
  { label: "+970", value: "+970" },
  { label: "+507", value: "+507" },
  { label: "+675", value: "+675" },
  { label: "+595", value: "+595" },
  { label: "+51", value: "+51" },
  { label: "+63", value: "+63" },
  { label: "+48", value: "+48" },
  { label: "+351", value: "+351" },
  { label: "+974", value: "+974" },
  { label: "+40", value: "+40" },
  { label: "+7", value: "+7" },
  { label: "+250", value: "+250" },
  { label: "+1-869", value: "+1-869" },
  { label: "+1-758", value: "+1-758" },
  { label: "+1-784", value: "+1-784" },
  { label: "+685", value: "+685" },
  { label: "+378", value: "+378" },
  { label: "+239", value: "+239" },
  { label: "+966", value: "+966" },
  { label: "+221", value: "+221" },
  { label: "+381", value: "+381" },
  { label: "+248", value: "+248" },
  { label: "+232", value: "+232" },
  { label: "+65", value: "+65" },
  { label: "+421", value: "+421" },
  { label: "+386", value: "+386" },
  { label: "+677", value: "+677" },
  { label: "+252", value: "+252" },
  { label: "+27", value: "+27" },
  { label: "+82", value: "+82" },
  { label: "+211", value: "+211" },
  { label: "+34", value: "+34" },
  { label: "+94", value: "+94" },
  { label: "+249", value: "+249" },
  { label: "+597", value: "+597" },
  { label: "+46", value: "+46" },
  { label: "+41", value: "+41" },
  { label: "+963", value: "+963" },
  { label: "+886", value: "+886" },
  { label: "+992", value: "+992" },
  { label: "+255", value: "+255" },
  { label: "+66", value: "+66" },
  { label: "+228", value: "+228" },
  { label: "+676", value: "+676" },
  { label: "+1-868", value: "+1-868" },
  { label: "+216", value: "+216" },
  { label: "+90", value: "+90" },
  { label: "+993", value: "+993" },
  { label: "+688", value: "+688" },
  { label: "+256", value: "+256" },
  { label: "+380", value: "+380" },
  { label: "+971", value: "+971" },
  { label: "+44", value: "+44" },
  { label: "+1", value: "+1" },
  { label: "+598", value: "+598" },
  { label: "+998", value: "+998" },
  { label: "+678", value: "+678" },
  { label: "+379", value: "+379" },
  { label: "+58", value: "+58" },
  { label: "+84", value: "+84" },
  { label: "+967", value: "+967" },
  { label: "+260", value: "+260" },
  { label: "+263", value: "+263" },
];
exports.investment = [{ label: "Cash investment", value: "Cash Investment" }];
exports.investmentAr = [{ label: "الاستثمار النقدي", value: "Cash Investment" }];
// Customizable Area End

