import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { downloadIcon, userImage } from "./assets";
import { toast } from "react-toastify";
import { getToken, handleDownload, removeToken } from "../../../components/src/utils.web";
import { getStorageData } from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web";
interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  is_local_admin: boolean;
  id_proof: string;
  service_type: string;
  country: string;
  photo_information: string | null;
  language: string;
}
interface Attributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  status : string | null;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  account: Account;
}
interface Notification {
  id: string;
  type: string;
  attributes: Attributes;
}
export type TStatus = "pending" | "rejected" | "approved" | "submitted" | "completed" | "fill form" | "not started";
export interface IPaginationData {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  currentCount: number;
  totalCount: number;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  loading: boolean;
  totalProfitPopup: boolean;
  activeTab: number;
  isDialogOpen: boolean;
  anchorEl : HTMLElement | null;
  selectedNotificationId: string | null;
  data: Notification[];
  profitList: {label: string, value: string}[];
  buttonList: {label: string, value: string}[];
  investmentData: {
    name: string,
    percentage: string,
    total: string,
    image: string,
    color: string
  }[];
  tradesData:any,
  weeklyData:any,
  weeklyGraphData:any;
  graphWidth: number;
  see_all:boolean;
  transactionsActivity: {
    date: string,
    type: string,
    amount: string,
    balance: string,
    amountColor: string,
  }[];
  calendarOpen:boolean;
  selectedDate: null;
  tempDate: any;
  selectedStartDate:any,
  selectedEndDate:any,
  startDate:any,
  endDate:any,
  inputRef: any;
  weekOption:boolean,
  searchPopUp:boolean,
  transactionList:boolean,
  buttonBox:boolean,
  paginationData: IPaginationData,
  activeData: {
    first: number
  },
  data22:any;
  tradeSum:any;
  // Customizable Area End
}
interface SS {}

export default class WeeklyPerformanceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  downloadReportApiCallId: string = "";
  getGraphDataApiCallId: string = "";
  getTradesDataApiCallId: string = "";
  getWeeklyRecordsApiId: string = "";
  apiCallIdDownloadWeeklyReport: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      graphWidth: 1405,
      token: "",
      loading: false,
      startDate: "",
      endDate:"",
      activeTab: 0,
      totalProfitPopup: false,
      isDialogOpen : false,
      anchorEl:null,
      selectedNotificationId: null,
      data: [],
      transactionList:false,
      buttonBox:false,
      calendarOpen:false,
  selectedDate: null,
  tempDate: [],
  selectedStartDate: null,
  selectedEndDate: null,
      paginationData: {
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
        totalPages: 0,
        currentCount: 0,
        totalCount: 0
      },
      tradesData: [],
      weeklyData:[],
      weeklyGraphData:[],
      see_all:false,
      profitList: [
        {
          label: this.handleTranslationChange('thisWeek'),
          value: "This Week"
        },
        {
          label: this.handleTranslationChange('thisMonth'),
          value: "This Month"
        },
      ],
      buttonList: [
        {
          label: this.handleTranslationChange('newWithdrawReq'),
        value: "new"
      },
        {label: this.handleTranslationChange('newDepositReq'),
        value: "new"
      }
      ],
      inputRef: React.createRef(),
      searchPopUp:false,
      weekOption: false,
      activeData: {
        first: 0,
      },
      data22: [
        { name: "Group A", value: 500, fill: "#10B981" },
        { name: "Group B", value: 500, fill: "#EF4444" },
      ],
      investmentData: [
        {
          name: "Total Investments",
          percentage: "-18%",
          total: "$357,000",
          image: downloadIcon,
          color: "#EF4444"
        },
        {
          name: "Total Profits",
          percentage: "+2.5%",
          total: "$400,000",
          image: downloadIcon,
          color: "#F59E0B"
        },
        {
          name: "Total Returns",
          percentage: "+25%",
          total: "$390,000",
          image: downloadIcon,
          color: "#10B981"
        },
        {
          name: "Avg in Return",
          percentage: "+50%",
          total: "$399,000",
          image: downloadIcon,
          color: "#1B4FE4"
        },
      ],
      transactionsActivity: [
        {
          date: "Jan 04, 2024",
          type: "Withdraw",
          amount: "-15000",
          balance: "$64900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "-21000",
          balance: "$85900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Withdraw",
          amount: "+20000",
          balance: "$74900",
          amountColor: "#10B981",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "+20000",
          balance: "$84900",
          amountColor: "#10B981",
        },
      ],
      tradeSum:""

    };
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
  ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    window.addEventListener('resize', this.getChartWidth)
    this.getTradesDataApi();
    this.getChartWidth();
    this.getWeeklyRecordsApi();
    this.getGraphDataApi();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.tradesData !== prevState.tradesData) {
      this.calculateTradeTotals22()
    }
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.getTradesDataApiCallId == webApiRequestCallId){        
        this.handleTradesDataApi(message)
      }
      if(webApiRequestCallId == this.getWeeklyRecordsApiId){
        this.handleWeeklyRecordsDataApi(message)
      }
      if(webApiRequestCallId == this.getGraphDataApiCallId){
        this.handleGraphData(message)
      }
      if(webApiRequestCallId == this.apiCallIdDownloadWeeklyReport){
        this.getDownloadedReportResponse(apiResponse)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  stepColor = (status: TStatus) => {
    const colors: {[key: string]: string} = {
      Pending: "#F59E0B",
      Received: '#10B981',
      Transferred:"#10B981",
      completed: '#10B981',
      rejected: '#EF4444',
      submitted: '#10B981',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }
    return colors[status] || '#F1F5F9';
  }

  handleTradesDataApi = async ( message: Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      if(apiResponse && apiResponse.data?.length > 0) { 
        this.setState({tradesData:apiResponse?.data})
        this.setState({tradeSum: apiResponse})
  } else if (
    apiResponse.errors &&
    apiResponse.errors.length > 0 &&
    apiResponse.errors[0].token
  ) {
    const goTologin = new Message(getName(MessageEnum.NavigationMessage));
    goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goTologin);
    removeToken();
    toast.error(apiResponse.errors[0].token)
  }
  }

  calculateTradeTotals22 = () => {
    const totals = this.state.tradesData.reduce(
      (acc: any, item: any) => {
        const { trade_type, trade_value } = item.attributes;
        return {
          ...acc,
          successfulTotal: this.handleCondition(
            trade_type === "Successful Trade",
            acc.successfulTotal + trade_value,
            acc.successfulTotal
          ),
          openTotal: this.handleCondition(
            trade_type === "Open Trade",
            acc.openTotal + trade_value,
            acc.openTotal
          ),
        };
      },
      { successfulTotal: 0, openTotal: 0 }
    );
    this.setState({
      data22: [
        { name: "Group A", value: totals.successfulTotal, fill: "#10B981" },
        { name: "Group B", value: totals.openTotal, fill: "#EF4444" }
      ]
    });
  };
  
  
  calculateTradeTotals = () => {
    return this.state.tradesData.reduce(
      (acc: { successfulTotal: number; openTotal: number }, item: any) => {
        const { trade_type, trade_value } = item.attributes;
  
        return {
          ...acc,
          successfulTotal: this.handleCondition(
            trade_type === "Successful Trade",
            acc.successfulTotal + trade_value,
            acc.successfulTotal
          ),
          openTotal: this.handleCondition(
            trade_type === "Open Trade",
            acc.openTotal + trade_value,
            acc.openTotal
          ),
        };
      },
      { successfulTotal: 0, openTotal: 0 }
    );
  };

  handleWeeklyRecordsDataApi = ( message: Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiResponse) { 
      const metaResponse = apiResponse.meta
      const pagination: IPaginationData = {
        currentPage: metaResponse?.current_page || 1,
        nextPage: metaResponse?.next_page || null,
        prevPage: metaResponse?.prev_page || null,
        totalPages: metaResponse?.total_pages || 1,
        currentCount: metaResponse?.current_count || metaResponse.length,
        totalCount: metaResponse?.total_count || metaResponse.length,
      };
  
      this.setState({weeklyData :apiResponse.data, paginationData: pagination})
      }

  }

  convertToNumber = (value: string | number) => {
    if (typeof value === 'number') {
      return value;
    }
    let cleanedStr = value.replace(/[^0-9.-]+/g, '');
    cleanedStr = cleanedStr.replace(/\.(?=.*\.)/g, '');
    cleanedStr = cleanedStr.replace(/(?!^)-/g, '');
    cleanedStr = cleanedStr.replace(/^\.*/g, '');
    if (cleanedStr.startsWith('-.') || cleanedStr.startsWith('.')) {
      cleanedStr = cleanedStr.replace(/^-\./, '-').replace(/^\./, '');
    }
    const numberValue = parseFloat(cleanedStr);
    return numberValue;
  };
  

  handleGraphData = (message: Message) => {
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiResponse && !apiResponse.error){
      const response = apiResponse.data
      const format = response.map((item: {attributes: {profit_amount: string; created_at: string}}) => {
        return{
          amount: item.attributes.profit_amount,
          amountt: this.convertToNumber(item.attributes.profit_amount),
          create: item.attributes.created_at,
          createdDate: new Date(item.attributes.created_at).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })
        }
      })
      this.setState({weeklyGraphData: format})
    }else if (apiResponse && apiResponse.error) {
      toast.error(apiResponse.error);
    }
  }

  getChartWidth = () => {
    const screenWidth = window.innerWidth;
    if(screenWidth <= 900) {
      this.setState({graphWidth: screenWidth - 70})
    }else
    this.setState({graphWidth: screenWidth - 400})
  };

  handleCalendarOpen = () => {
    if (this.state.inputRef.current) {
      this.state.inputRef.current.showPicker();
    }
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  handleWeekOption = () => {
    this.setState({weekOption: !this.state.weekOption})
  }

  handleActiveTab = (index: number) => {
    this.setState({activeTab: index})
  }

  handleTransactionList = () => {
    this.setState({transactionList: !this.state.transactionList, see_all: true}, this.getWeeklyRecordsApi)
  }

  listDataOftrans = () => {
    this.setState({transactionList: false})
  }
  
  handleSearchPopUp = () => {
    this.setState({searchPopUp: !this.state.searchPopUp})
  }

  handleButtonBox = () => {
    this.setState({buttonBox: !this.state.buttonBox})
  }

  displayButton = () => {
    this.setState({weekOption:false})
  }

  handlefalseSearch = () => {
    this.setState({searchPopUp: false})
  }

  handleTranslationChange = (keyName: string) => {
    return this.props.i18n?.t(keyName)
  }

  getGraphDataApi = () => {
    const token = getToken()
    const header = {
      "Content-Type": webConfigJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGraphDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.startDate ? `${webConfigJSON.getGraphDataApiEndPoint}?start_date=${this.state.startDate.toLocaleDateString('en-GB',{
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })}
      &end_date=${this.state.endDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })}` : `${webConfigJSON.getGraphDataApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTradesDataApi = () => {
    const token = getToken()
    const header = {
      "Content-Type": webConfigJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTradesDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getTradesDataApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getWeeklyRecordsApi = () => {
    const token = getToken();
    const header = {
      "Content-Type": webConfigJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWeeklyRecordsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getWeeklyRecordsApiEndPoint}?&see_all=${this.state.see_all}&page=${this.state.paginationData.currentPage}&per_page=9`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,currentPage: next
      },
      weeklyData:[],
    }), () => this.getWeeklyRecordsApi())
  }
  handleDataChange = () =>{
    this.setState({calendarOpen:false})
  }

  openCalendar = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
  };
  handleDateChange = (newDate:any) => {
    this.setState({ tempDate: newDate });
  };
  handleCalendarCancelBtn = () => {
    this.setState({ tempDate: [], calendarOpen: false });
  };
 
  handleCalendarSaveBtn = () => {
    const { tempDate } = this.state;
    if (tempDate && Array.isArray(tempDate)) {
      const [startDate, endDate] = tempDate;
      this.setState({
        startDate,
        endDate,
        tempDate: [],
        calendarOpen: false,
      }, this.getGraphDataApi);
  
    }
  };

  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

   withdrawPage = () => this.navigateTo("WithdrawRequest")

   depositPage = () => this.navigateTo("DepositRequest")

   downloadReport = async() => {
    const { startDate, endDate } = this.state;
    const baseUrl = webConfigJSON.downloadReportApiEndPoint;
    let token = getToken();
  
    const apiUrl = (startDate && endDate)
      ? `${baseUrl}?start_date=${startDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}&end_date=${endDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}`
      : baseUrl;

      const header = {
        "Content-Type": webConfigJSON.validationApiContentType,
        token
      };
  
      const requestMessage = ApiRequest({
        header: header,
        endPoint: apiUrl,
        method: "GET",
      });
  
      this.apiCallIdDownloadWeeklyReport = requestMessage.messageId;
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
  
  };

  getDownloadedReportResponse = ( responseJson: any) => {
    if(responseJson && responseJson.pdf_url) {
      handleDownload(responseJson.pdf_url, "Weekly Report.pdf")
    } else {
      toast.error("Sorry! Something went wrong.")
    }
  }

  // Customizable Area End
}
