// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { CountryData } from "react-phone-input-2";/*  */
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web";
import { TStatus } from "./MultipageFormsController.web";
import { getToken, removeToken } from "../../../components/src/utils.web";

export const configJSON = require("./config");

export interface ISOFormValues {
  standingValue: string;
  standingValueConfirm: string;
  bankName: string;
  swiftCode: string;
  accountName: string;
  accountNum: string;
  bankAddress: string;
  phonecode: string;
  phonecodeConfirm: string;
  country: string;
}
export interface Props {
  navigation: any;
  id: string;
  i18n?: any;
}
interface S {
  open: boolean;
  form: {
    name: string;
    label: string;
    placeholder: string;
    isMandatory: boolean;
    type: string;
  }[];
  withdrawalDropDown: boolean;
  countryOption: boolean;
  withdrawalDropDown2: boolean;
  withdrawalOptions: { label: string; value: string }[];
  popupboxforBack: boolean;
  sendRequest: boolean;
  formattedDate: string;
  inputRef: any;
  country: string;
  initialSOFormValues: ISOFormValues;
  standing_order_form_inProgress: string;
  loading: boolean;

}
interface SS {}

interface TouchedType {
  standingValue: string;
  standingValueConfirm: string;
  bankName: string;
  swiftCode: string;
  accountName: string;
  accountNum: string;
  bankAddress: string;
}
// Customizable Area End

export default class StandingOrderFormsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      open: false,
      withdrawalDropDown: false,
      inputRef: React.createRef(),
      country: "United State",
      formattedDate: `${this.formatDate(new Date())}`,
      withdrawalDropDown2: false,
      countryOption: false,
      popupboxforBack: false,
      sendRequest: false,                           
      withdrawalOptions: [            
        { label: "USD", value: "United States Dollar" },
        { label: "EUR", value: "Euro" },{ label: "GBP", value: "British Pound" },    
        { label: "JPY", value: "Japanese Yen" },{ label: "AUD", value: "Australian Dollar" },         
        { label: "CAD", value: "Canadian Dollar" },{ label: "CHF", value: "Swiss Franc" },{ label: "CNY", value: "Chinese Yuan" },
        { label: "SEK", value: "Swedish Krona" },{ label: "NZD", value: "New Zealand Dollar" },
        { label: "MXN", value: "Mexican Peso" },{ label: "SGD", value: "Singapore Dollar" },
        { label: "HKD", value: "Hong Kong Dollar" },{ label: "NOK", value: "Norwegian Krone" },
        { label: "OMR", value: "Omani Rial" },{ label: "KRW", value: "South Korean Won" },
        { label: "TRY", value: "Turkish Lira" },{ label: "RUB", value: "Russian Ruble" },
        { label: "INR", value: "Indian Rupee" },{ label: "BRL", value: "Brazilian Real" },
        { label: "ZAR", value: "South African Rand" },{ label: "PHP", value: "Philippine Peso" },
        { label: "CZK", value: "Czech Koruna" },{ label: "IDR", value: "Indonesian Rupiah" },
        { label: "MYR", value: "Malaysian Ringgit" },{ label: "HUF", value: "Hungarian Forint" },
        { label: "ISK", value: "Icelandic Krona" },{ label: "HRK", value: "Croatian Kuna" },
        { label: "BGN", value: "Bulgarian Lev" },{ label: "RON", value: "Romanian Leu" },{ label: "DKK", value: "Danish Krone" },
        { label: "THB", value: "Thai Baht" },{ label: "PLN", value: "Polish Zloty" },
      ],
      form: [
        { name: "accountNum",
          label: "Bank Account Number",
          placeholder: "Enter your bank account number",
          isMandatory: true,
          type: "text"},
        { name: "bankAddress",
          label: "Bank Address",
          placeholder: "Enter your bank address",
          isMandatory: true,
          type: "text"},
      ],
      initialSOFormValues: {
        standingValue: "",
        standingValueConfirm: "",
        bankName: "",
        swiftCode: "",
        accountName: "",
        accountNum: "",
        bankAddress: "",
        phonecode: "OMR",
        phonecodeConfirm: "OMR",
        country: "United States",
      },
      standing_order_form_inProgress: '',
      loading: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  standingFormID : string = "";
  apiCallIdLastSOFormStatus: string = "";
  apiCallIdUpdatePreferredDate: string = "";

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.standingFormID || apiRequestCallId === this.apiCallIdUpdatePreferredDate) {
      this.handleSubmitSOFormResponse(responseJson)
    } else if (apiRequestCallId === this.apiCallIdLastSOFormStatus) {
      this.getWithdrawFormStatusResponse(responseJson)
    }
  }

  async componentDidMount() {
    this.getLastStandingOrderWithdrawFormStatusRequest();
 }

  standingFormSchema = () => {
    return Yup.object().shape({
      standingValue: Yup.number()
        .required("Please enter your standing order value")
        .min(1, "standing value must be at least 1"),
      standingValueConfirm: Yup.number()
        .oneOf([Yup.ref("standingValue"), null], "standing values must match")
        .required("Please confirm your standing order value"),
      bankName: Yup.string().required("Please enter your bank name"),
      swiftCode: Yup.string().required("Please enter your swift code"),
      accountName: Yup.string().required(
        "Please enter your name in bank account"
      ),
      accountNum: Yup.string().required("Please enter your account number"),
      bankAddress: Yup.string().required("Please enter your bank address"),
    });
  };

  standingFormSchemaAr = () => {
    return Yup.object().shape({
      standingValue: Yup.number()
        .required("الرجاء ادخال قيمة الطلب الدائم الخاص بك")
        .min(1, "قيمة الطلب الدائم يجب أن تكون على الأقل 1"),
      standingValueConfirm: Yup.number()
        .oneOf([Yup.ref("standingValue"), null], "تأكيد قيمة السحب يجب أن تتطابق مع قيمة السحب")
        .required("الرجاء ادخال قيمة الطلب الدائم الخاص بك"),
      bankName: Yup.string().required("الرجاء ادخال اسم البنك"),
      swiftCode: Yup.string().required("الرجاء ادخال رمز السويفت الخاص بك"),
      accountName: Yup.string().required("الرجاء ادخال الاسم في الحساب البنكي"),
      accountNum: Yup.string().required("الرجاء ادخال رقم الحساب البنكي"),
      bankAddress: Yup.string().required("الرجاء ادخال عنوان البنك"),
    });
  };

  getErrorMessage = (touched: FormikTouched<TouchedType>, errors: FormikErrors<TouchedType>, value: string ) => {
    const style = {
      marginTop: "2px",fontSize: "12px", color: "#DC2626", fontFamily: "DIN Next LT Arabic Regular",
    };
    return (
      <Typography style={style}>
        {touched[value as keyof TouchedType] && errors[value as keyof TouchedType]}
      </Typography>
    );
  };

  handleOpenPopup = () => {
    this.setState({ popupboxforBack: !this.state.popupboxforBack });
  };

  handleBackClick = () => {
    this.props.navigation.navigate("WithdrawRequest");
  };

  handleSendPopup = () => {
    this.setState({ sendRequest: !this.state.sendRequest });
  };

  withdrawDropdown2 = () => {
    this.setState({ withdrawalDropDown2: false });
  };

  withdrawDropdown = () => {
    this.setState({ withdrawalDropDown: false });
  };

  handleBankName = () => {
    this.setState({ countryOption: !this.state.countryOption });
  };

  handleDateChange = (event: { target: { value: string } }) => {
    const dateValue = event.target.value;
    if (dateValue) {
      const date = new Date(dateValue);
      const formatted = this.formatDate(date);
      this.setState({ formattedDate: formatted });
    }
  };

  formatDate = (date: Date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  handleCalendarOpen = () => {if (this.state.inputRef.current) { return this.state.inputRef.current.showPicker();}};

  handlePhoneChange = (value: string, countryData: CountryData) => {
    this.setState({ country: countryData.name });
  };

  handleCondition = (
    condition: any,
    truePart: any,
    falsePart: any
  ) => {
    return condition ? truePart : falsePart;
  };

  standingFormPost = (values: {
    standingValue: string;
    standingValueConfirm: string;
    bankName: string;
    swiftCode: string;
    accountName: string;
    accountNum: string;
    bankAddress: string;
    phonecode: string;
    phonecodeConfirm: string;
    country: string;
  }) => {
    return;
  };

  standingFormPostApi = async (values: {
    standingValue: string;
    standingValueConfirm: string;
    bankName: string;
    swiftCode: string;
    accountName: string;
    accountNum: string;
    bankAddress: string;
    phonecode: string;
    phonecodeConfirm: string;
    country: string;
  }) => {
    const requestId = await getStorageData("withdrawRequestId")
    const formData = new FormData();
  formData.append("data[attributes][request_id]", requestId);
  formData.append("data[attributes][amount]", values.standingValue);
  formData.append("data[attributes][amount_confirmation]", values.standingValueConfirm);
  formData.append("data[attributes][bank_name]", values.bankName);
  formData.append("data[attributes][swift_code]", values.swiftCode);
  formData.append("data[attributes][name_in_bank_account]", values.accountName);
  formData.append("data[attributes][bank_account_number]", values.accountNum);
  formData.append("data[attributes][bank_address]", values.bankAddress);
  formData.append("data[attributes][currency]", values.phonecode);
  formData.append("data[attributes][preferred_date]", this.state.formattedDate);
  formData.append("data[attributes][country]", this.state.country);
  formData.append("data[attributes][withdraw_request_type]", "standing_order");
  const headers = {
    contentType: configJSON.contentTypeFormData,
    token: getToken()
  };
  const standingFormData = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  standingFormData.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  this.standingFormID = standingFormData.messageId;
  standingFormData.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.standingPostApi
  );
  standingFormData.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  standingFormData.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(standingFormData.id, standingFormData);
}

navigateToDepositR = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "WithdrawRequest"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
};


handleSubmitSOForm = (values: ISOFormValues) => {
  const { standing_order_form_inProgress, formattedDate } = this.state;
  return standing_order_form_inProgress ? this.updatePreferredDateRequest(formattedDate) : this.standingFormPostApi(values)
}

// last SO withdrawal status Request
getLastStandingOrderWithdrawFormStatusRequest = async () => {
  let token = getToken();

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
  };

  const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.lastStandingOrderEndpoint,
      method: "GET",
  });

  this.apiCallIdLastSOFormStatus = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({loading: true})
};

// last SO withdrawal status Response
getWithdrawFormStatusResponse = async (responseJson: any) => {
  const { data, message } = responseJson?.data?.[0] || {};
  const attributes = data?.attributes;
  
  const newUser = message === "No Withdraw Request Found";
  if (attributes) {
    const statusMap: { [key: string]: TStatus } = {
      accepted: "approved",
      pending: "pending",
      rejected: "rejected",
    };

    const newStatus = statusMap[attributes.status] || "not started";

    const inProgress = newStatus === 'pending' || newStatus === 'approved';

    if(inProgress) {
      const initialSOFormValues = {
        standingValue: attributes.omr_amount,
        standingValueConfirm: attributes.omr_amount_confirmation,
        bankName: attributes.bank_name,
        swiftCode: attributes.swift_code,
        accountName: attributes.name_in_bank_account,
        accountNum: attributes.bank_account_number,
        bankAddress: attributes.bank_address,
        phonecode: attributes.currency,
        phonecodeConfirm: attributes.currency,
        country: attributes.country,
    }
    
    this.setState({
      standing_order_form_inProgress: attributes.id,
      initialSOFormValues,
      formattedDate: attributes.preferred_date, 
      loading: false,   
    });
    }
  }

  if (newUser) {
    this.setState({ 
      initialSOFormValues: {
        standingValue: "",
        standingValueConfirm: "",
        bankName: "",
        swiftCode: "",
        accountName: "",
        accountNum: "",
        bankAddress: "",
        phonecode: "OMR",
        phonecodeConfirm: "OMR",
        country: "United States",
      },
      standing_order_form_inProgress: '',
     });
  }
  if (
    responseJson.errors &&
    responseJson.errors.length > 0 &&
    responseJson.errors[0].token
  ) {
    const goTologin = new Message(getName(MessageEnum.NavigationMessage));
    goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goTologin);
    removeToken()
    toast.error(responseJson.errors[0].token)
  }
  this.setState({loading: false})
};

// update Preferred Date Request
updatePreferredDateRequest = async (preferred_date: string) => {
  const { standing_order_form_inProgress } = this.state;
  let token = getToken();

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
  };

  const body = {
    "data": {
      "attributes": {
        "preferred_date": preferred_date
      }
    }
  }
  

  const requestMessage = ApiRequest({
      header: header,
      payload: JSON.stringify(body),
      endPoint: `${configJSON.updatePreferredDateEndpoint}/${standing_order_form_inProgress}`,
      method: "PATCH",
  });

  this.apiCallIdUpdatePreferredDate = requestMessage.messageId;

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

// handle Standing order form Response
handleSubmitSOFormResponse = async (responseJson: any) => {
  if(responseJson.errors) {

    if(Object.keys(responseJson.errors[0]).includes("token")) {
      toast.error("Token is expired")
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");

      this.send(message);
    } else if (responseJson.errors[0].preferred_date) {
      responseJson.errors.map((err: {preferred_date: string}) => toast.error(err.preferred_date));
    } else {
      responseJson.errors.forEach((errorMessage: string) => {
      toast.error(errorMessage);
    });
  }
  } else if(responseJson?.data?.data?.attributes?.status === 'auto_rejected') {

    responseJson.messages.map((item: {message: string}) => toast.error(item));

  } else if (responseJson?.data?.data?.attributes?.status === 'rejected'){

    responseJson.messages.map((item: {message: string}) => toast.error(item));

  } else {
    
    this.setState({
      sendRequest: true
    }, this.getLastStandingOrderWithdrawFormStatusRequest)

    toast.success(this.props.i18n?.t('standing_req_send_successfully'))    
  }
};
  // Customizable Area End
}
