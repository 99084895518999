import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { getToken, handleDownload, removeToken } from "../../../components/src/utils.web";

export interface TradeIndustry {
  id: number;
  created_at: string;
  updated_at: string;
  trade_name: string;
  trade_percentage: string;
}

interface TopTradeIndustries {
  data: TradeIndustry[];
  trade_count: number;
}


interface WeeklyData {
  week: string; 
  amount: number;
  date: string; 
  time: string; 
  amountString: string;
}


interface MonthlyAnalyticsResponse {
  cumulative_profit: number | string;
  change_from_last_month: number | string;
}

interface MonthlyAnalyticsErrorResponse {
  error: string;
}

interface YearToDateProfitSuccessResponse {
  yearly_profit: string
}

type YearToDateProfitResponse = YearToDateProfitSuccessResponse | MonthlyAnalyticsErrorResponse;

interface ChartModel {
  name: string;
  value: number;
  fill: string;
}

const staticData = [
  { name: 'Group B', value: 500 },
  { name: 'Group D', value: 100 }
]

const COLORS = ['#00C49F', '#FF8042'];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: Array<{ name: string, value: number }>,
  COLORS: string[],
  txtInputValue: string,
  graphWidth: number;
  buttonBox: boolean;
  activeTab: number;
  topTradeIndustries: TopTradeIndustries;
  monthlyListing: string[];
  yearlyListing: string[];
  monthlyReportGraph: WeeklyData[];
  monthlyAnalytics: MonthlyAnalyticsResponse;
  yearToDateProfit: string;
  anchorElMonthly: HTMLElement | null;
  anchorElYearly: HTMLElement | null;
  selectedMonth: string;
  selectedYear: string;
  topTradeChart: ChartModel[];
  monthlyAnalyticsChart: ChartModel[];
  tempDate: any;
  startDate:any,
  endDate:any,
  calendarOpen:boolean;
  isFullScreen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VisualAnalyticsReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIdTopTradeIndustries: string = "";
  apiCallIdMonthlyListing: string = "";
  apiCallIdYearlyListing: string = "";
  apiCallIdMonthlyReportGraph: string = "";
  apiCallIdMonthlyAnalytics: string = "";
  apiCallIdYearToDateProfit: string = "";
  apiCallIdDownloadReport: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [{name: 'Rate of successful Trave', value: 91}],
      COLORS: COLORS,
      txtInputValue: "",
      graphWidth: 1200,
      buttonBox: false,
      activeTab: 0,
      topTradeIndustries: {
        data: [],
        trade_count: 0,
      },
      monthlyListing: [],
      yearlyListing: [],
      monthlyReportGraph: [],
      monthlyAnalytics: {
        change_from_last_month: '', 
        cumulative_profit: '', 
      },
      yearToDateProfit: '0',
      anchorElMonthly: null,
      anchorElYearly: null,
      selectedMonth: '',
      selectedYear: '',
      topTradeChart: [],
      monthlyAnalyticsChart: [
        { name: 'Group B', value: 220, fill: 'green' },
        { name: 'Group D', value: 444, fill: 'red' }
      ],
      tempDate: null,
      startDate: "",
      endDate: "",
      calendarOpen: false,
      isFullScreen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdTopTradeIndustries]: this.getTopTradeIndustriesResponse,
      [this.apiCallIdMonthlyListing]: this.getMonthlyListingResponse,
      [this.apiCallIdYearlyListing]: this.getYearlyListingResponse,
      [this.apiCallIdMonthlyReportGraph]: this.getMonthlyReportGraphResponse,
      [this.apiCallIdMonthlyAnalytics]: this.getMonthlyAnalyticsResponse,
      [this.apiCallIdYearToDateProfit]: this.getYearToDateProfitResponse,
      [this.apiCallIdDownloadReport]: this.getDownloadedReportResponse,

      // Add more API call IDs and handlers as needed
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apierror = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };



  // Customizable Area Start
  async componentDidMount() {
    this.getTopTradeIndustriesRequest();
    this.getMonthlyListingRequest();
    this.getYearlyListingRequest();
    this.getMonthlyReportsGraphRequest();
    this.getMonthlyAnalyticsRequest();
    this.getYearToDateProfitRequest();
    window.addEventListener('resize', this.getChartWidth)
  }

  // Request functions
  getTopTradeIndustriesRequest = async () => {
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.topTradedIndustriesEndpoint}`,
      method: "GET",
    });

    this.apiCallIdTopTradeIndustries = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMonthlyListingRequest = async () => {
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.monthlyListingEndpoint}`,
      method: "GET",
    });

    this.apiCallIdMonthlyListing = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getYearlyListingRequest = async () => {
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.yearlyListingEndpoint}`,
      method: "GET",
    });

    this.apiCallIdYearlyListing = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTranslationChange = (keyName: string) => {
    return this.props.i18n?.t(keyName)
  }

  getMonthlyReportsGraphRequest = async () => {
    const { startDate, endDate } = this.state;
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const startDateGb = this.formatDateToGB(startDate);
    const endDateGb = this.formatDateToGB(endDate)

    const params = new URLSearchParams();

    if(startDateGb) params.append("start_date", startDateGb);
    if(endDateGb) params.append("end_date", endDateGb)


    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.monthlyReportsGraphEndpoint}?${params.toString()}`,
      method: "GET",
    });

    this.apiCallIdMonthlyReportGraph = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDownloadGraphRequest = async() => {
    const { startDate, endDate } = this.state;
    let token = getToken();

    const startDateGb = this.formatDateToGB(startDate);
    const endDateGb = this.formatDateToGB(endDate)

    const params = new URLSearchParams();

    if(startDateGb) params.append("start_date", startDateGb);
    if(endDateGb) params.append("end_date", endDateGb)

    const apiUrl = `${configJSON.downloadReportEndpoint}?${params.toString()}`;

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: apiUrl,
      method: "GET",
    });

    this.apiCallIdDownloadReport = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getMonthlyAnalyticsRequest = async () => {
    const { selectedMonth } = this.state;
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const params = new URLSearchParams();

    if (selectedMonth) params.append("month", selectedMonth);

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.monthlyAnalyticsEndpoint}?${params.toString()}`,
      method: "GET",
    });

    this.apiCallIdMonthlyAnalytics = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getYearToDateProfitRequest = async () => {
    const { selectedYear } = this.state;
    let token = getToken();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token
    };

    const params = new URLSearchParams();

    if (selectedYear) params.append("year", selectedYear);

    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.yearToDateProfitEndpoint}?${params.toString()}`,
      method: "GET",
    });

    this.apiCallIdYearToDateProfit = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   // Response functions
  getTopTradeIndustriesResponse = (responseJson: any, errorResponse: any) => {

    if (responseJson.data) {
      const topTradeChart = responseJson.data.map((item: any, index: number) => ({
        name: item.attributes.trade_name,
        value: this.parseTradePercentage(item.attributes.trade_percentage),
        fill: this.getColorByIndex(index)
      }));
      const topTradeIndustries: TopTradeIndustries = {
        data: responseJson.data.map((item: any) => ({
            id: item.attributes.id,
            created_at: item.attributes.created_at,
            updated_at: item.attributes.updated_at,
            trade_name: item.attributes.trade_name,
            trade_percentage: item.attributes.trade_percentage
        })),
        trade_count: responseJson.trade_count
      };

      this.setState({
        topTradeChart,
        topTradeIndustries
      })
    } else if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      const goTologin = new Message(getName(MessageEnum.NavigationMessage));
      goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
      goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(goTologin);
      removeToken()
      toast.error(responseJson.errors[0].token)
    }

  };

  getMonthlyListingResponse = (responseJson: any, errorResponse: any) => {

    if (responseJson.months && Array.isArray(responseJson.months)) {
      const monthlyListing: string[] = responseJson.months

      this.setState({ monthlyListing })
    }
  }

  getYearlyListingResponse = (responseJson: any, errorResponse: any) => {

    if (responseJson.years && Array.isArray(responseJson.years)) {
        const yearlyListing: string[] = responseJson.years.map((yr: number) => yr.toString());
        this.setState({ yearlyListing });
    } else {
        toast.warn("Invalid response format");
    }
};

getMonthlyReportGraphResponse = (responseJson: any, errorResponse: any) => {

  const data = responseJson.data;

  const weeklyData: WeeklyData[] = data.map((item: any, index: number) => {
      const attributes = item.attributes;
      const weekLabel = `Week ${index + 1}`;

      const createdAt = attributes.created_at;
      const [date] = createdAt.split(' ');
      const formattedDate = `${date} ${createdAt.split(' ')[1]} ${createdAt.split(' ')[2]}`; 
      const formattedTime = `${createdAt.split(' ')[3]} ${createdAt.split(' ')[4]}`; 

      return {
          week: weekLabel,
          amount: this.convertToNumber(attributes.profit_amount),
          date: formattedDate,
          time: formattedTime,
          amountString: attributes.profit_amount
      };
  });

  // Update state with the transformed data
  this.setState({ monthlyReportGraph: weeklyData });
};


processMonthlyPieData(cumulative_profit: string, change_from_last_month: string): ChartModel[] {
  const cumulativeProfitValue = this.convertToNumber(cumulative_profit);
  const changeFromLastMonthValue = this.convertToNumber(change_from_last_month);

  if (cumulativeProfitValue === 0 && changeFromLastMonthValue === 0) {
    return [{ name: "No Data", value: 1, fill: "#94A3B8" }];
  }

  return [
    { name: 'Cumulative Profit', value: cumulativeProfitValue, fill: 'green' },
    { name: 'Change From Last Month', value: changeFromLastMonthValue, fill: 'red' }
  ];
}

getMonthlyAnalyticsResponse = (responseJson: any, errorResponse: any) => {


  const response: MonthlyAnalyticsResponse = responseJson;

  if ("error" in response) {
      return null;
  }

  const { cumulative_profit, change_from_last_month } = response;

  

  const monthlyPieData = this.processMonthlyPieData(cumulative_profit as string, change_from_last_month as string);

  this.setState({
    monthlyAnalytics: {
      cumulative_profit,
      change_from_last_month,
  },
  monthlyAnalyticsChart: monthlyPieData
  });
};

getYearToDateProfitResponse = (responseJson: any, errorResponse: any) => {

  const response: YearToDateProfitResponse = responseJson;

  if ("error" in response) {
      return null;
  }

  const { yearly_profit } = response;

  this.setState({
    yearToDateProfit: yearly_profit
  });
};

getDownloadedReportResponse = (responseJson: any, errorResponse: any) => {
  if(responseJson && responseJson.pdf_url) {
    handleDownload(responseJson.pdf_url, "Monthly Report.pdf")
  } else {
    toast.error("Sorry! Something went wrong.")
  }
}



  // Internal functions
  getChartWidth = () => {
    const screenWidth = window.innerWidth;
    if(screenWidth <= 900) {
      this.setState({graphWidth: screenWidth - 70})
    }else
    this.setState({graphWidth: screenWidth - 400})
  };

  convertToNumber = (str: string) => {
    const isPercentage = str.includes('%');
    
    let cleanedStr = str.replace(/[^0-9.-]/g, '');
    cleanedStr = cleanedStr.replace(/-{2,}/g, '-').replace(/\.(?=.*\.)/g, '');
    
    if (isPercentage) {
        cleanedStr = cleanedStr.replace(/-/g, '');
    }

    let number = parseFloat(cleanedStr);
    
    if (isPercentage) {
        number /= 100;
    }

    return number;
};



  parseTradePercentage(tradePercentage: string): number {
    return parseFloat(tradePercentage.replace('%', ''));
  }

  handleMonthlyPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElMonthly: event.currentTarget });
  };

  handleMonthlyPopoverClose = () => {
    this.setState({ anchorElMonthly: null });
  };

  handleYearlyPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElYearly: event.currentTarget });
  };

  handleYearlyPopoverClose = () => {
    this.setState({ anchorElYearly: null });
  };

  handleMonthSelection = (monthParams: string) => {
    const selectedMonth = this.state.monthlyListing.find(
      month => month === monthParams
    );
  
    if (selectedMonth) {
  
      this.setState({
        selectedMonth,
      }, () => {
        this.handleMonthlyPopoverClose();
        this.getMonthlyAnalyticsRequest();
      });
    } else {
      toast.error('Selected month not found in the list');
    }
  }

  handleYearSelection = (yearParams: string) => {
    const selectedYear = this.state.yearlyListing.find(
      year => year == yearParams
    );
  
    if (selectedYear) {
      this.setState({
        selectedYear,
      }, () => {
        this.handleYearlyPopoverClose();
        this.getYearToDateProfitRequest();
      });
    } else {
      toast.error('Selected year not found in the list');
    }
  }

  getColorByIndex(colorIndex: number): string {
    const colors = ["#1C1917", "#1B4FE4", "#E63946", "#2A9D8F"];
    
    return colors[colorIndex % colors.length];
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  withdrawPage = () => this.navigateTo("WithdrawRequest")

   depositPage = () => this.navigateTo("DepositRequest")

   navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

  handleButtonBox = () => {
    this.setState({buttonBox: !this.state.buttonBox})
  }

  handleActiveTab = (index: number) => {
    this.setState({activeTab: index})
  }

  openCalendar = () => {
    this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
  };
  handleDateChange = (newDate:any) => {
    this.setState({ tempDate: newDate });
  };
  handleCalendarCancelBtn = () => {
    this.setState({ tempDate: null, calendarOpen: false });
  };
 
  handleCalendarSaveBtn = () => {
    const { tempDate } = this.state;
    if (tempDate && Array.isArray(tempDate)) {
      const [startDate, endDate] = tempDate;
      this.setState({
        startDate,
        endDate,
        tempDate: null,
        calendarOpen: false,
      }, this.getMonthlyReportsGraphRequest);
  
    }
  };

  formatDateToGB(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  handleFullscreenToggle = () => {
    this.setState((prevState) => ({ isFullScreen: !prevState.isFullScreen }));
  };
  
  // Customizable Area End
}
