import React, { useEffect, useRef } from 'react';
import { Box, Typography, Modal, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { cross2 } from '../../blocks/multipageforms/src/assets';
import { CheckCircle } from "@material-ui/icons"
import { toast } from 'react-toastify';
import { downloadSmallIcon } from '../../blocks/customform/src/assets';
import { useTranslation } from 'react-i18next';

interface ConfirmSaveDraftProps {
    open: boolean;
    downloadLink: string;
    handleCloseModal: () => void;
    handledDownload: () => void;
}


const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        [theme.breakpoints.down(598)]: {
            gap: "16px",
            width: "100%",
            padding: "0px",
        },
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    heading: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        color: "#334155",
        lineHeight: '32px',
        [theme.breakpoints.down(598)]: { fontSize: "18px", },
    },
    label: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "18px",
        color: "#475569",
        lineHeight: '26px',
        textAlign: 'center',
        [theme.breakpoints.down(598)]: { fontSize: "16px", },
    },
    modalContainer: {
        [theme.breakpoints.down(938)]: {
            width: "calc(55% - 48px)",
        },
        [theme.breakpoints.down(853)]: {
            width: "calc(60% - 48px)",
        },
        [theme.breakpoints.down(782)]: {
            width: "calc(65% - 48px)",
        },
        [theme.breakpoints.down(722)]: {
            width: "calc(70% - 48px)",
        },
        [theme.breakpoints.down(670)]: {
            width: "calc(75% - 48px)",
        },
        [theme.breakpoints.down(626)]: { width: "calc(80% - 48px)" },
        [theme.breakpoints.down(598)]: { width: "90%" },
        width: "calc(40% - 48px)",
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%'
    },
    discardBtn: {
        width: '100%',
        color: '#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial'
    },
    saveBtn: {
        width: '100%',
        color: '#fff',
        fontFamily: 'DIN Next LT Arabic Regular',
        border: '1px solid #1B4FE4',
        backgroundColor: '#1B4FE4',
        borderRadius: '8px',
        padding: '10px 16px',
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#1B4FE4',
            color: '#fff',
        }
    },
    crossStyle: {
        [theme.breakpoints.down(598)]: {
            width: "40px",
            height: "40px"
        },
    },
    endRight: {
        display: 'flex',
        
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px',
        maxHeight: '30vh',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '3px',
          minHeight: '30px',
        },
        '&.no-scroll': {
          overflowY: 'hidden',
        },
      },
    circleStyle: {
        width: '80px', height: '80px', color: '#1B4FE4',
        [theme.breakpoints.down(598)]: {width: '60px', height: '60px', },
    }
}));

const SuccessALModal = ({
    open,
    downloadLink,
    handleCloseModal,
    handledDownload,
}: ConfirmSaveDraftProps) => {
    const classes = useStyles();
    const centerFlexRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkScroll = () => {
            if (centerFlexRef.current) {
                const { scrollHeight, clientHeight } = centerFlexRef.current;
                if (scrollHeight > clientHeight) {
                    centerFlexRef.current.classList.remove('no-scroll');
                } else {
                    centerFlexRef.current.classList.add('no-scroll');
                }
            }
        };

        checkScroll();
        window.addEventListener('resize', checkScroll);

        return () => window.removeEventListener('resize', checkScroll);
    }, []);


    const handleDownload = async (fileUrl: string, fileName: string) => {
        if (!fileUrl && !fileName) {
          toast.error('Either fileUrl or fileName must be provided');
          return;
        }
    
        try {
          if (fileUrl) {
            const response = await fetch(fileUrl);
            
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName || 'download');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        } catch (err) {
            window.open(fileUrl, '_blank');
        }
      };

      const { i18n } = useTranslation();

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                p={2}
            >
                <Box
                    bgcolor="white"
                    p={4}
                    borderRadius="8px"
                    boxShadow={3}
                    maxWidth="500px"
                    width="100%"
                    display={"flex"}
                    flexDirection={"column"}
                    gridGap={20}
                >
                    <Box className={classes.endRight} style={{justifyContent: i18n?.language === 'ar' ? "flex-start" : 'flex-end'}}>
                        <img src={cross2} onClick={handleCloseModal} className={classes.crossStyle} />
                    </Box>
                   {/* @ts-ignore */}
                   <Box ref={centerFlexRef} className={`${classes.centerFlex} no-scroll`}>
                    <CheckCircle className={classes.circleStyle} />
                    <Typography className={classes.heading} gutterBottom>
                        { i18n?.t('successfull')}
                    </Typography>
                    <Typography className={classes.label} gutterBottom>
                    {i18n?.t('process_for_generate')}
                    </Typography>
                    </Box>
                    <Box className={classes.flexBox} style={{direction: i18n?.language === 'ar'? 'rtl': 'ltr'}}>
                            <Button onClick={handleCloseModal} className={classes.discardBtn}>
                                {i18n?.t('clear')}
                            </Button>
                            <Button startIcon={<img src={downloadSmallIcon} />} onClick={() => {handleDownload(downloadLink, "AccountLetter.pdf"); handledDownload()}} className={classes.saveBtn}>
                                {i18n?.t('download')}
                            </Button>
                        </Box>
                </Box>
            </Box>
        </Modal >
    );
};

export default SuccessALModal;
